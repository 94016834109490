<template>
  <div>
    <account-credit
      :user-id="userId"
      :currency="payload.currency"
      @balance="form.creditBalance = $event"
    />
    <payment-methods
      :user-id="userId"
      context="select"
      @selected="form.paymentMethod = $event"
    />
    <step-controls
      :enabled="formIsValid"
      @next="$emit('next')"
      @back="$emit('back')"
    />
  </div>
</template>

<script>
export default {
  name: "PaymentDetailsStep",
  components: {
    "account-credit": () => import("@shared/account/_accountCredit"),
    "payment-methods": () => import("@shared/account/_paymentMethods")
  },
  props: {
    payload: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      form: {
        creditBalance: null,
        paymentMethod: null
      }
    };
  },
  computed: {
    userId() {
      return this.payload.userId;
    },
    formIsValid() {
      if (this.$_.isEmpty(this.form.paymentMethod)) return false;
      return true;
    }
  },
  watch: {
    "form.paymentMethod"(paymentMethod) {
      this.$emit("payload", { paymentMethod });
    },
    "form.creditBalance"(creditBalance) {
      this.$emit("payload", { creditBalance });
    }
  }
};
</script>
